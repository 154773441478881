import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const Room = ({ location, page }) => {
  const data = useStaticQuery(
    graphql`
      {
        global: sanityGlobalSettings {
          streetAddress
          addressLocality
          addressRegion
          postalCode
          hotelImage {
            asset {
              gatsbyImage(width: 1200, height: 600)
            }
          }
        }
        header: sanityGlobalHeader {
          tel
        }
        footer: sanityGlobalFooter {
          socialMediaLinks {
            url
          }
        }
      }
    `
  )

  const socialMedia = []

  data.footer.socialMediaLinks.forEach(link => socialMedia.push(link.url))

  const amenities = []

  page._rawAmenities.forEach(amenity => {
    amenities.push({
      '@type': 'LocationFeatureSpecification',
      value: 'True',
      name: amenity.title,
    })
  })

  const hotel = {
    '@context': 'http://schema.org',
    '@type': 'HotelRoom',
    name: page.title,
    url: location.href,
    image: page._rawGallery.images[0].asset.url,
    description: page._rawDescription[0].children[0].text,

    containedInPlace: {
      '@type': 'Hotel',
      name: 'Soul Valley',
      url: 'https://soulvalley.com',
      logo: 'https://soulvalley.com/soul-valley-logo.jpg',
      image: data.global.hotelImage.asset.gatsbyImage.images.fallback.src,
      priceRange: '€€€',
      description:
        'A boutique hotel in the middle of nature. A must visit natural sanctuary in the south of Sardinia, a magical place to completely recover from your busy daily life.',
      sameAs: socialMedia,
      telephone: data.header.tel,
      hasMap:
        'https://www.google.com/maps/place/09048+Sinnai,+Metropolitan+City+of+Cagliari,+Italy/@39.3092968,9.1475967,11.24z/data=!4m5!3m4!1s0x12e0b55cb53345ed:0x5f9626d74dd7b7b0!8m2!3d39.3415037!4d9.3007367',
      checkinTime: '7:30',
      checkoutTime: '23:00',
      additionalType: 'https://en.wikipedia.org/wiki/Boutique_hotel',
      address: {
        '@type': 'PostalAddress',
        streetAddress: data.global.streetAddress,
        addressLocality: data.global.addressLocality,
        addressRegion: data.global.addressRegion,
        postalCode: data.global.postalCode,
        addressCountry: 'IT',
      },
    },
    bed: {
      '@type': 'BedDetails',
      numberOfBeds: page.numberOfBeds,
    },
    occupancy: {
      '@type': 'QuantitativeValue',
      maxValue: page.maxOccupancy,
    },
    amenityFeature: amenities,
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(hotel) }}
    />
  )
}

export default Room

Room.propTypes = {
  location: PropTypes.object,
  page: PropTypes.object,
}
